<template>
<div>
<div class="container" style="width: 400px" v-if="apiDown">
    <center>
        <h3>System upgrade in progress, please try again in 5 minutes.</h3>
    </center>
</div>
<div class="container-fluid" style="padding-top:15px !important" v-if="!apiDown">
    <div class="row page-header-banner" style="margin-top:0px">
        <div class="col-xs-12">
            <center>
                <h1 class="page-title chspaces">High School Profiles</h1>
            </center>
        </div>
    </div>
</div>
<div class="container main-body" style="margin-top:-45px !important" id="form" v-if="!apiDown">
    <div class="row">
        <div class="col-md-12" style="border: 2px solid #999; border-radius: 0 0 10px 10px;">
            <HSProfile :hsprofile="selectedDept" :cur_length="selectedDept.curricula.length" :iveeData="{user: 'Example, Student', curriculum: 'Example Curriculum'}" @load-other-profile="fetchDepartment"></HSProfile>
        </div>
    </div>
</div>
</div>
</template>

<script>

    import Vue from 'vue';
    import { mapState } from 'vuex'
    import VueResource from 'vue-resource';
    Vue.use(VueResource);
    import {
        ivAlertMixin, 
        ivSpinnerMixin,
        getParameterByName,
        ivUserInfoMixin 
    } from "@/mixins/base-components-pack.js";
    import HSProfile from "../components/HSProfile.vue";
    import ivbus from "@/utils/ivbus.js";
    import apiClient from "../utils/apiClient.js";

    export default {
        mixins: [ivAlertMixin, ivSpinnerMixin, ivUserInfoMixin],
        components: {
            HSProfile
        },
        props: [
            'staticaddr',
            'serveraddr',
            'ameliaaddr',
        ],
        computed: mapState(['apiDown']),
        data: function () {
            return {
                selectedDept: {
                    contacts: [],
                    curricula: [],
                    emails: [],
                    phones: [],
                    testing_data: []
                }
            }
        },
        mounted: function() {
            var hsp = getParameterByName("p");
            if (hsp) {
                this.fetchDepartment(hsp);
            }
        },
        methods: {
            fetchDepartment: function (dept_uid) {
                var that = this;
                var spinId = this.spinStart();

                apiClient.get("/api/hsprofile/department/", {
                    params: {
                        unique_id: dept_uid
                    }
                }).then(function (response) {
                    that.spinStop(spinId);
                    that.$set(that, 'selectedDept', response.data.dept);
                    ivbus.setFlag("hsprofile", response.data.data);
                }).catch(function () {
                    that.spinStop(spinId);
                });
            },
        }
    }
</script>

<style>
body {
    line-height: 1.42857143 !important;
}
</style>